.ingredients {
    margin-top: 140px;
    &__hero {
        height: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("../../assets/images/ingredientsHero.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__statement-container {
        width: 1085px;
        display: flex;
        gap: 90px;
        margin: 72px auto 94px auto;
    }
    &__statement {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 36px;
        flex: 1;
        align-items: center;
        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 115%;
            text-align: center;
        }
    }
}
