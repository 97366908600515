.footer {
    background: var(--primary-color);
    padding: 42px 0;
    display: flex;
    // align-items: center;
    justify-content: center;
    &__img {
        width: 100%;
    }
    &__container {
        max-width: 1260px;
        width: calc(100% - 144px);
        display: flex;
        flex-direction: column;
        gap: 48px;
        &-details {
            display: flex;
            justify-content: space-between;
            gap: 89px;
        }
        &-text {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
            text-decoration: none;
            white-space: nowrap;
        }
    }
    &__links-box {
        display: flex;
        flex-direction: column;
        gap: 8.75px;

        a {
            text-decoration: underline;
            &:hover {
                color: unset !important;
            }
        }
    }
    &__contact-box {
        display: flex;
        flex-direction: column;
        gap: 8.75px;
        max-width: 355px;
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
        }
    }
    &__copyrights {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        align-self: flex-start;
        gap: 30px;
        div {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            text-decoration-line: underline;
            &:hover {
                color: unset !important;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .footer {
        &__copyrights {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            gap: 82px;
        }
        &__container-details {
            // justify-content: flex-start;
            gap: 82px;
        }
    }
}

@media only screen and (max-width: 740px) {
    .footer {
        padding: 48px 0;
        &__container {
            margin: 0 72px;
        }

        &__copyrights {
            width: 100%;
            align-self: flex-start;           
        }
    }
}

@media only screen and (max-width: 570px) {
    .footer {
        &__container-details,
        &__copyrights {
            gap: 20px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .footer {
        &__container {
            width: calc(100% - 72px);
            margin: 0 36px;
            gap: 45px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .footer {
        &__container {
            &-text {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }
        }
        &__links-box {
            gap: 22px;
        }
        &__contact-box {
            gap: 16px;
            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 120%;
            }
        }
        &__copyrights {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            a {
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
            }
        }
    }
}
