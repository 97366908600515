.dotselector {
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    width: 100%;
    height: 5px;
    min-width: 1080px;
    justify-content: space-between;
    &__dot {
        width: 36px;
        height: 36px;
        border: 5px solid var(--primary-color);
        background-color: white;
        border-radius: 50%;
        position: relative;
        transition: all 0.3s ease-out;
        cursor: pointer;

        &.active::before {
            content: " ";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            transform: translate(-50%, -50%);
            height: 20px;
            border-radius: 50%;
            background-color: var(--secondary-color);
        }
    }
}

.boxselector {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
    &__box {
        padding: 24px 36px;
        background: white;
        color: var(--primary-color);
        border: 2px solid var(--primary-color);
        display: flex;
        flex-direction: column;
        gap: 8px;
        transition: all 0.2s ease-out;

        &.active {
            background: var(--primary-color);
            color: white;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 105%;
        transition: all 0.2s ease-out;

        color: inherit;
    }
    &__body {
        transition: all 0.2s ease-out;
        color: inherit;
        font-weight: 500;
        font-size: 20px;
        line-height: 110%;
    }
}

@media only screen and (max-width: 1200px) {
    .dotselector {
        width: 100%;
        min-width: 500px;
    }
}

@media only screen and (max-width: 1200px) {
    .boxselector {
        gap: 8px;
      
        &__title {
            font-size: 16px;
        }
        &__body {
           
            font-size: 16px;
        }
    }
    
}
