.altbutton {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: (--dark-white-color);
    font-family: "Fira Sans";
    background-color: var(--primary-color);
    outline: none;
    border: none;
    color: #ffffff;
    width: 311px;
    padding: 20px 0;
    transition: all 0.1s ease-out;

    &.secondary {
        color: var(--primary-color);
        background-color: var(--dark-white-color);
        border: 2px solid #1c454e;
    }
    &.transparent {
        color: var(--primary-color);
        background-color: transparent;
        border: 2px solid #1c454e;
    }

    &:hover {
        color: white;
        background-color: var(--secondary-color);
        border-color: transparent;
    }
}
