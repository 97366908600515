.ingredientsModal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 8800;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.25);

    &__modal {
        display: flex;
        flex-direction: column;
        background-color: #eef3ed;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        width: 640px;
        padding: 40px 105px 70px 47px;
        position: relative;
        gap: 20px;
        background-image: url("../../../assets/images/modal_bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        svg {
            position: absolute;
            top: 30px;
            right: 26px;
            cursor: pointer;
        }
    }
    &__ingredient-name {
        font-family: "Frank Ruhl Libre";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 120%;
        color: var(--primary-color);
    }
    &__heading {
        font-family: "Nunito", sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: var(--primary-color);
        margin-bottom: 9px;
    }
    &__about {
        p {
            font-family: "Nunito", sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #224b52;
        }
    }
    &__research {
        ul {
            padding: 0;
            li {
                font-family: "Nunito";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                list-style: none;
                text-decoration-line: underline;
                color: #224b52;
            }
        }
    }
}

.modal__key-benefits {
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    width: 310px;
    font-size: 16px;
    line-height: 38px;
    border-bottom: 1px solid #000000;
}
