.navbar-style {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-style: none;
  border-width: 0px;
}

.spanStyling {
  color: $pink;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-color: $pink !important;
  border-color: $pink !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: $white !important;
  border-color: $primary !important; /*set the color you want here*/
  color: $primary !important;
  border-width: medium !important;
}

// Important
.bodyImage {
  width: 186px;
  height: 189px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #58b3bc !important;
}

/* Laptops */
@media only screen and (min-width: 1024px) {
}
/* Tablets */
@media only screen and (max-width: 768px) {
  #brand-logo-nav {
    width: 140.18px !important;
    height: 16.13px !important;
  }

  .shopping-basket-icon {
    width: 16.86px !important;
    height: 22.7px !important;
  }
}

/* Smartphones */
@media only screen and (max-width: 576px) {
  .navbar-toggler {
    // padding: 0.25rem 0.75rem;
    // font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent !important;
    border-radius: 0.25rem;
  }

  .nav-item {
    padding-top: 0.5rem !important;
  }
}
