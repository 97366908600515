
// Tweak because of !important for flex-wrap class;
.conditional-wrap {
  flex-wrap: wrap;
  flex-direction: row;

  @media (min-width: 768px) {
    flex-wrap: nowrap;

    &.reverse {
      flex-direction: row-reverse;
    }
  }
}
