.multipleBreedsInfoModal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 8800;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.25);

    &__modal {
        display: flex;
        flex-direction: column;
        background-color: #eef3ed;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        width: 640px;
        padding: 40px 40px 70px 47px;
        position: relative;
        gap: 20px;
        // background-image: url("../../../../assets/images/modal_bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        svg {
            position: absolute;
            top: 30px;
            right: 26px;
            cursor: pointer;
        }
    }
    &__title-name {
        font-family: "Frank Ruhl Libre";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 120%;
        color: var(--primary-color);
    }
    &__about {
        p {
            font-family: "Nunito", sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #224b52;
        }
    }
}