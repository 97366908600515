.select__button {
    outline: none;
    background: #ffffff;
    border: 2px solid #1c454e;
    width: 165.5px;
    padding: 11px 15.75px;
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: var(--primary-color);
    font-family: "NunitoRegular", sans-serif;
    height: 48px;
    transition: all 0.3s ease-out;

    &.active {
        background-color: var(--primary-color);
        color: white;
    }
}


@media only screen and (max-width: 650px) {
    .select__button {
        outline: none;
        background: #ffffff;
        border: 2px solid #1c454e;
        width: 135.5px;
    }
    
}
