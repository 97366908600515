.has-bg-image {
  background-image: url('../../../assets/images/aboutUs/dogFoodWithHandHoldingSpoon.png');
  background-size: contain;
  background-position-x: calc(100% + 5rem);
  background-position-y: center;
  background-repeat: no-repeat;

  & .container {
    display: grid;
  }
}

@media only screen and (max-width: 648px) {
  .has-bg-image {
    background-position-y: 30px;
  }
}

@media only screen and (max-width: 430px) {
  .has-bg-image {
    background-position-x: calc(100% + 6rem);
    background-position-y: 112px;
  }
}
