.button {
    padding: 24px 48px;
    border-radius: 50px;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 100%;
    text-transform: capitalize;
    color: white;
    background-color: var(--primary-color);
    transition: all 0.3s ease-out;
    cursor: pointer;
    border: none;
    outline: none;
    &:hover {
        color: var(--primary-color);
        background-color: white;
    }
    &.primary-reverse {
        color: var(--primary-color);
        background-color: white;
        border: 2px solid var(--primary-color);

        &:hover {
            background-color: var(--primary-color);

            color: white;
        }
    }
    &.secondary {
        color: white;
        background-color: var(--secondary-color);
        &:hover {
            color: white;
            background-color: var(--primary-color);
        }
    }
    &.secondary-reverse {
        color: white;
        background-color: var(--primary-color);
        &:hover {
            color: white;
            background-color: var(--secondary-color);
        }
    }
}
