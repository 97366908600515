.faq {
    margin-top: 135px;
    &__container {
        grid-column-start: 3;
        grid-column-end: 11;
        display: flex;
        flex-direction: column;
        gap: 47px;
        padding: 72px 0;
    }
    h1 {
        align-self: center;
    }
    &__nav-links {
        display: flex;

        align-items: center;
        justify-content: space-between;
    }

    &__nav-link {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: var(--primary-color);
        transition: all 0.3s ease-out;
        padding: 6px 3px;
        cursor: pointer;
        border-bottom: 2px solid;
        border-color: transparent;

        &.active {
            background-color: #eef4f2;
            border-color: var(--primary-color);
        }
    }
    &__questions {
        border-top: 1px solid var(--primary-color);
        margin-top: 24px;
        &-container {
            display: flex;
            flex-direction: 24px;
            flex-direction: column;
        }
        &-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: var(--primary-color);
            margin-left: 22px;
        }
    }

    .grid {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .faq {
        .grid {
            display: block;
        }

        &__container {
            margin: 0 8vw;
            gap: 0;
        }

        &__nav-links {
            flex-wrap: wrap;
            margin-top: 48px;
            margin-bottom: 20px;
        }

        &__nav-link {
            width: 50%;
            text-align: center;
            margin-bottom: 10px;

            &:nth-child(2n) {
                margin-left: 15px;
                width: calc(50% - 15px);
            }

            &:last-child{
                margin: 0 auto;
            }
        }

        &__questions {
            .questionbox {
                &__question {
                    svg {
                        margin-left: 20px;
                        width: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .faq {
        &__container {
            margin: 0 3vw;
            padding: 28px 0;
        }
    }
}
