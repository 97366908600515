.carouselLoader__container {
    display: flex;
    align-items: center;
    gap: 12px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -10px);
    // width: 365px;

    span {
        width: 16px;
        height: 16px;
        border: 3px solid #ffffff;
        border-radius: 50%;
        cursor: pointer;
        &.active {
            background: var(--secondary-color);
            border-color: transparent;
        }
    }
}

@media only screen and (max-width: 1170px) {
    .carouselLoader__container {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: center;
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        width: 365px;
    }
}

@media only screen and (max-width: 520px) {
    .carouselLoader__container {
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        width: 225px;
        div {
            height: 6px;
            border-radius: 8px;

            &::before {
                width: 68.42px;
                height: 6px;
            }
        }
    }
}
