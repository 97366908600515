.dog-health-section.has-hero-section-bg {
  background-color: #90b0b2;
  background-image: url('../../../assets/images/aboutUs/productWithDogsTailNearIt.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;

  & .container {
    display: grid;
  }
}

@media only screen and (max-width: 768px) {
  .dog-health-section.has-hero-section-bg {
    background-color: #90b0b2;
    background-image: url('../../../assets/images/aboutUs/productWithDogsTailNearIt-mobile.png');
    background-position: 100% 42%;
    & .container {
      display: block;
    }
  }
}
