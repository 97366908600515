.stats-item {
  .circle {
    width: 3em;
    height: 3em;
    min-width: 3em;
    min-height: 3em;
    border-radius: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}