.quizCreatingSupplement {
    h3 {
        margin: 48px 0;
        width: 640px;
    }
    p {
        margin-top: 48px;
        font-weight: 500;
        font-size: 18px;
        line-height: 115%;
        text-align: center;
        color: #1c454e;
        margin-bottom: 24px;
    }

    &__reviewsbox {
        display: flex;
        width: 932px;
        gap: 24px;
        flex-direction: column;
        margin-bottom: 120px;
    }
}

@media only screen and (max-width: 1050px) {
    .quizCreatingSupplement {
        padding: 0 72px;
        img {
            width: 100%;
        }
        &__reviewsbox {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 800px) {
    .quizCreatingSupplement {
        h3 {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 550px) {
    .quizCreatingSupplement {
        padding: 0 36px;
        h3 {
            margin-bottom: 34px;
        }
        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;
            margin-top: 32px;
        }
        &__reviewsbox {
            margin-bottom: 50px;
        }
        img{
            width: auto;
        }
    }
}
