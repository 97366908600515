.ingredientsContainer {
    width: 1115px;
    margin: 0 auto 64px auto;
    display: flex;
    flex-direction: column;
    &__form {
        display: flex;
        justify-content: space-between;
        position: relative;

        .search input {
            outline: none;
            padding: 12px 48px 12px 24px;
            border: 1px solid #1c454e;
            border-radius: 16px;
            width: 306px;
            height: 48px;
            background: url("../../../assets/icons/search.svg");
            background-repeat: no-repeat;
            background-position: 252px 12px;
            color: var(--primary-color);
            font-family: "Nunito", sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-transform: capitalize;
            transition: all 0.2s;
            &::placeholder {
                font-family: "Nunito";
                font-style: italic;
                color: rgba(28, 69, 78, 0.5);
            }
        }
    }
    &__dropdown-box {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        width: 218px;
        padding: 12px 24px;
        font-family: "Nunito", sans-serif;
        border: 1px solid #1c454e;
        border-radius: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        svg {
            transition: all 0.1s ease-out;
            &.active {
                transform: rotate(180deg);
            }
        }
    }
    &__dropdown-container {
        position: absolute;
        background: white;
        border-radius: 16px;
        padding: 36px 48px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        top: 64px;
        right: 0px;
        display: none;
        z-index: 400;

        &.active {
            display: flex;
        }
        form {
            display: flex;
            gap: 36px;
        }

        div {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 186px;
        }

        label {
            display: flex;
            align-items: center;
            gap: 17px;
            position: relative;

            .text {
                font-family: "Nunito", sans-serif;
                margin-left: 34px;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #1c454e;
            }
        }

        label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 17px;
            width: 17px;
            background: #eef4f2;
            border: 1px solid rgba(28, 69, 78, 0.5);
            border-radius: 4px;
        }
        label:hover input ~ .checkmark {
            background-color: #ccc;
        }
        label input:checked ~ .checkmark {
            background-color: var(--secondary-color);
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        label input:checked ~ .checkmark:after {
            display: block;
        }
    }
    &__ingredientsList {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 23px;
        grid-row-gap: 48px;
        margin-top: 50px;
    }
    &__ingredientsList ~ div {
        align-self: center;
        button {
            margin-top: 72px;
        }
    }
    &__ingredient-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        gap: 16px;
        padding: 0 23.5px;
        div {
            font-family: "Frank Ruhl Libre";
            font-weight: 700;
            font-size: 20px;
            line-height: 105%;
            text-align: center;
            color: #1c454e;
        }
    }
}
