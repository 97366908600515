.text-carousel {
  .word {
    position: absolute;
    width: 480px;
    left: 0;
    top: 0;
    transition: transform 200ms ease, opacity 200ms ease;
    transform: translateY(0);
    line-height: initial;

    &.active {
      transform: translateY(0);
      opacity: 1;
    }

    &.bottom {
      transform: translateY(150%);
      opacity: 0;
    }

    &.top {
      transform: translateY(-150%);
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .text-carousel {
    .word {
      width: auto;
    }
  }
}
