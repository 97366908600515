.prelaunch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-white-color);
    min-height: 100vh;
    position: relative;

    img {
        position: absolute;
        top: 40px;
        right: 40px;
        cursor: pointer;
    }

    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 115%;
        text-align: center;
        color: #1c454e;
        margin: 0;
    }
    form {
        display: flex;
        align-items: center;
        margin-top: 24px;
        flex-direction: column;
        input.prelaunch__email-input {
            border: none;
            outline: none;
            width: 350px;
            border: 1px solid var(--primary-color);
            font-family: "Nunito", sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: rgb(28, 69, 78);
            background: none;
            border-radius: 16px;
            padding: 14px 20px 14px 50px;
            text-align: center;
            background-image: url("../../assets/icons/mail.svg");
            background-repeat: no-repeat;
            background-position: 14px 50%;
            transition: all 0.1s ease-out;
            &::placeholder {
                font-style: italic;
                color: rgba(28, 69, 78, 0.5);
            }
            &:focus {
                background-color: rgb(238, 244, 242);
            }
        }
        button {
            margin-top: 30px;
            width: 350px;
        }
    }
    &__checkbox {
        display: flex;
        align-items: center;
        margin-top: 16px;
        gap: 8px;
        position: relative;
        cursor: pointer;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        .text{
            margin-left: 36px;
        }
    }
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        height: 17px;
        width: 17px;
        transform: translate(0, -50%);
        background: #eef4f2;
        border: 1px solid rgba(28, 69, 78, 0.5);
        border-radius: 4px;
    }
    label:hover input ~ .checkmark {
        background-color: #ccc;
    }
    label input:checked ~ .checkmark {
        background-color: var(--secondary-color);
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    label input:checked ~ .checkmark:after {
        display: block;
    }
}

@media only screen and (max-width: 1024px) {
    .prelaunch {
        margin-top: -100px;
        padding: 0 72px;
    }
}
@media only screen and (max-width: 720px) {
    .prelaunch img {
        width: 215px;
        height: auto;
        top: 60px;
        right: 40px;
    }
}

@media only screen and (max-width: 500px) {
    .prelaunch {
        padding: 0 36px;
        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 115%;
            text-align: center;
            color: #1c454e;
            margin: 0;
        }
        form {
            width: 100%;
            input {
                width: 100%;
            }
            button {
                margin-top: 30px;
                width: 100%;
            }
        }
    }
}
