@import 'bootstrap';
@import 'typography';
@import 'app_defaults';
@import 'carousel';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #1c454e;
  --primary-dark-color: #224b52;
  --secondary-color: #ff8a5b;
  --dark-white-color: #eef4f2;
}

body {
  margin: 0;
  // margin-top: 130px !important;
  font-family: 'Nunito', sans-serif;
  color: $primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.grid {
  width: 1300px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  margin: 0 auto;
}

.carousel {
  margin-top: 130px;
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 770px) {
  .carousel {
    margin-top: 150px;
  }
}
@media screen and (max-width: 500px) {
  .carousel {
    margin-top: 135px;
  }
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consilas, "Courier New",
//     monospace;
// }
