.header-statement {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark-white-color);
}

.navigation {
  background: white;
  padding: 24px 78px 24px 106px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);

  &__cart {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: var(--secondary-color);
      border-radius: 50%;
      right: -5%;
    }
  }
}
.header__navbar {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
}

.navbar__hamburger {
  display: none;
}

.nav__menubar {
  background-color: var(--dark-white-color);
  outline: none;
  border: none;
  flex-direction: column;
  gap: 36px;
  align-items: center;
  transition: all 0.3s ease-out;
  height: 0px;
  overflow: hidden;
  display: flex;
  a {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-color);
    text-decoration: none;
  }
  &.active {
    padding: 33px 0;
    height: fit-content;
  }
}

.navbar__links-col {
  &-1,
  &-2 {
    display: flex;
    align-items: center;
    gap: 72px;
    flex: 1;
    a {
      font-weight: 400;
      font-size: 20px;
      font-family: 'NewSpirit';
      line-height: 22px;
      text-align: center;
      color: var(--primary-color);
      text-decoration: none;
      display: flex;
      align-items: end;
      gap: 16px;
    }
  }
  &-2 {
    justify-self: flex-end;
    display: flex;
    gap: 48px;
    justify-content: flex-end;
  }
}

@media (min-width: 767px) {
  .header__navbar a.nav-link {
    padding: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.navbar__cart-quantity {
  background-color: var(--primary-color);
  border-radius: 50%;
  span {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
  }
}
@media only screen and (max-width: 1350px) {
  .header__navbar {
    width: 100%;
    position: relative;
  }
}
@media only screen and (max-width: 1300px) {
  .header__navbar {
    width: 100%;
    .navigation__logo {
      margin: 0 30px;
    }
  }
  .navbar__links-col {
    &-1,
    &-2 {
      gap: 0px;
      justify-content: space-between;
      flex: 1;
      a {
        font-size: 15px;
        gap: 10px;
      }
    }
    &-2 {
      gap: 50px;
      justify-content: flex-end;
    }
  }
}
@media only screen and (max-width: 1240px) {
  .navigation {
    padding: 24px 46px;
  }
  .navbar__links-col {
    &-1,
    &-2 {
      gap: 30px;
      flex: 1;
      a {
        font-size: 14px;
        gap: 30px;
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .navbar__links-col {
    &-1,
    &-2 {
      a {
        font-size: 14px;
      }
    }
    &-2 {
      gap: 30px;
    }
  }
  .header__navbar .navigation__logo {
    margin: 0 15px;
  }
}
@media only screen and (max-width: 1000px) {
  .navbar__links-col {
    &-1,
    &-2 {
      flex: initial;
      a:not(.navbar__links__sign-in) {
        display: none;
      }

      .navbar__links__sign-in {
        flex-direction: column;
        align-items: center;
        gap: 0;
      }
    }
  }
  .navbar__hamburger {
    display: block;
  }
}
@media only screen and (max-width: 650px) {
  .header__navbar {
    justify-content: space-between;
  }
  .navigation {
    padding: 22px 32px;
  }
  .navbar__hamburger {
    right: 0px;
  }
  .header-statement {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
@media only screen and (max-width: 550px) {
  .navbar__hamburger {
    svg {
      width: 50px;
      height: auto;
    }
  }
}
@media only screen and (max-width: 480px) {
  .navbar__hamburger {
    svg {
      width: 44px;
      height: auto;
    }
  }
  .header__navbar {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 430px) {
  .navbar__hamburger {
    svg {
      width: 44px;
      height: auto;
    }
  }
}
