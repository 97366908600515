.call-to-action-section {
  .soft-flex-column {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      .md\:flex-row {
       flex-direction: row; 
      }
    }
  }
}