.signIn {
    width: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__logo {
        margin-bottom: 48px;
        &.confirm{
            margin-bottom: 32px;
        }
    }
    &__para {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 16px;
    }
    form {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        input {
            border: none;
            outline: none;
            width: 350px;
            border: 1px solid var(--primary-color);
            font-family: 'Nunito', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: rgb(28, 69, 78);
            background: none;
            border-radius: 16px;
            padding: 14px 20px 14px 50px;
            text-align: center;
            background-image: url("../../../assets/icons/mail.svg");
            background-repeat: no-repeat;
            background-position: 14px 50%;
            transition: all 0.1s ease-out;
            &::placeholder {
                font-style: italic;
                color: rgba(28, 69, 78, 0.5);
            }
            &:focus {
                background-color: rgb(238, 244, 242);
            }
        }
        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: var(--primary-color);
            text-align: center;
        }
        button {
            width: 350px;
        }
    }
}
