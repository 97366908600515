.dotdetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;

    &__details {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        flex: 1;
        &:first-of-type {
            align-items: flex-start;
        
            .dotdetails__body{
                text-align: start;
            }
        }
        &:last-of-type {
            align-items: flex-end;
        
            .dotdetails__body{
                text-align: end;
            }
        }
    }
    &__title {
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
        color: var(--primary-color);
    }
    &__body {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: var(--primary-color);
    }
}

@media only screen and (max-width: 900px) {
    .dotdetails{
        display: none;
    }
}