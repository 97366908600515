.formulationTable {
    background-color: #fff;
    width: 100%;

    .excludedRows {
        padding: 0.5rem; 
        font-weight: 700; 
        border-right-width: 1px; 
    }

    svg {
        display: inline-block;
        margin: auto;
        cursor: pointer;
    }
}