.questionbox {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--primary-color);

    &.active {
        svg {
            transform: rotate(180deg);
        }
    }

    &__question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 22px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: var(--primary-color);
        cursor: pointer;
        padding-right: 40px;
        svg {
            cursor: pointer;
            transition: all 0.3s ease-out;
        }
    }
    &__answer {
        white-space: break-spaces;
        color: var(--primary-color);
        margin-left: 22px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding-right: 115px;
        margin-bottom: 12px;
    }

    a {
        text-decoration: underline;
    }
}
