.quizTripleBreed__question {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.quizTripleBreed-btn {
    margin-top: 96px;
}

.quizTripleBreed form {
    margin-top: 36px;
    display: flex;
    gap: 16px;
    align-items: center;
    .quiz__select-box {
        select {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1250px) {
    .quizTripleBreed form {
        flex-direction: column;
        div {
            width: 100%;
        }
        .quiz__select-box {
            width: 100%;
            .MuiFormControl-root {
                width: 100%;
                margin: 0;
                min-width: max-content;
            }
            select {
                width: 100%;
            }
        }
    }
}
