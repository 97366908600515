.signInConfirm {
    &__title {
        margin-top: 32px;
        margin-bottom: 14px;
        font-weight: 700;
        font-size: 20px;
        line-height: 17px;
    }
    &__para {
        font-weight: 700;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        line-height: 18px;
        span {
            font-weight: 700;
        }
    }
}
