.reviewRefine {
    display: flex;
    flex-direction: column;
    margin-top: 130px;
    &__bg {
        width: 100%;
        margin-bottom: 100px;
    }
    &__personalized {
        display: flex;
        gap: 72px;
        align-items: center;
        padding-right: 182px;
        &-col {
            display: flex;
            flex-direction: column;
            gap: 36px;
            h1 {
                margin: 0;
            }
        }
        &-box {
            display: flex;
            flex-direction: column;
            gap: 12px;
            h3 {
                margin: 0;
            }
            p {
                margin: 0;
                font-weight: 400;
                font-size: 25px;
                line-height: 105%;
                color: var(--primary-color);
            }
        }
    }
    &__pupdate {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 100px 0;
        gap: 72px;
        h1 {
            margin: 0;
            width: 775px;
            text-align: center;
        }
        &-container {
            display: flex;
            gap: 48px;
            padding: 0 72px;
        }
        &-col {
            display: flex;
            gap: 48px;
            &-img {
                min-width: 250px;
                height: 100%;

                background: #d9d9d9;
                border-radius: 16px;
            }
            &-text {
                font-weight: 400;
                font-size: 25px;
                line-height: 105%;
                color: var(--primary-color);
            }
        }
    }
    &__quiz {
        display: flex;
        align-items: center;
        gap: 72px;
        background: #eef4f2;
        padding-right: 182px;
        &-box {
            display: flex;
            flex-direction: column;
            gap: 36px;
            padding: 36px 0;
            h1 {
                margin: 0;
            }
        }
        &-questions {
            display: flex;
            flex-direction: column;
            gap: 16px;
            h3 {
                margin: 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                justify-content: space-between;
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 115%;
                margin: 0;
            }
            &-container {
                display: flex;
                flex-direction: column;
                gap: 24px;
            }
        }
    }
    &__results {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 26px;
        margin: 100px 0;
        h1 {
            margin: 0;
            // width: 775px;
        }
        p {
            margin: 0 auto;
            padding: 0 116px;
            font-weight: 400;
            font-size: 25px;
            line-height: 105%;
            text-align: center;
            color: #1c454e;
        }
    }
    &__faqs {
        padding: 72px 0 100px 0;
        background-color: var(--dark-white-color);
        &-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 1080px;
            margin: 0 auto;
            
            h1 {
                margin: 0;
                text-align: center;
            }
            p {
                font-weight: 400;
                font-size: 25px;
                line-height: 105%;
                text-align: center;
                margin: 36px 0;
            }
        }
        &-container{
            display: flex;
            margin-top: 48px;
            flex-direction: column;
            padding-top: 12px;
            width: 100%;
            border-top: 1px solid var(--primary-color);
            gap: 12px;
            .questionbox{
                padding-bottom: 36px;
            }
        }
    }
}
