@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: "OdorMeanChey";
  src: local("OdorMeanChey"), url("./fonts/OdorMeanChey.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "NunitoRegular";
  src: local("NunitoRegular"), url("./fonts/nunito/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "NunitoSemiBold";
  src: local("NunitoSemiBold"), url("./fonts/nunito/Nunito-SemiBold.ttf") format ("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "NunitoBold";
  src: local("NunitoBold"), url("./fonts/nunito/Nunito-Bold.ttf") format ("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "NewSpirit";
  src: local("NewSpiritRegular"), url("./fonts/newSpirit/NewSpirit-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "NewSpirit";
  src: local("NewSpiritSemibold"), url("./fonts/newSpirit/NewSpirit-SemiBold.woff") format("truetype");
  font-weight: 600;
}

.navbar {
  font-family: 'Nunito', sans-serif;
  color: $primary;
  font-size: large;
  padding: 24px 0;
}

h1 {
  font-size: 50px;
  color: $primary;
  font-family: "Frank Ruhl Libre", serif;
  font-weight: 700;
  line-height: 104%;
  &.light {
    color: white;
  }
}

h2 {
  font-size: 30px;
  color: $primary;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  line-height: 105%;
  &.light {
    color: white;
  }
}

h3 {
  font-weight: 700;
  font-size: 30px;
  font-family: "Frank Ruhl Libre", serif;
  line-height: 105%;
  color: $primary;
  &.light {
    color: white;
  }
}
h4 {
  font-family: "Frank Ruhl Libre";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 105%;
  color: $primary;
  &.light {
    color: white;
  }
}

@media only screen and (max-width: 770px) {
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 95%;
  }

  h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 105%;
  }

  h3 {
    font-weight: 700;
    font-size: 26px;
    line-height: 105%;
  }
  h4 {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
  }
}
@media only screen and (max-width: 450px) {
  h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 105%;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 105%;
  }
  h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 105%;
  }
  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 105%;
  }
}

// h4 {
//   font-size: xx-large;
//   color: $primary;
//   font-family: 'Nunito', sans-serif;
//   font-weight: 700;
// }

p {
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  color: var(--primary-color);

  &.light {
    color: white;
  }
}
