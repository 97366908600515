.emailMagicLink {
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 16px 0;
    align-items: center;
    img {
        margin-top: 16px;
        margin-bottom: 32px;
    }
    &__title {
        font-weight: 700;
        font-size: 25px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        font-family: "Frank Ruhl Libre";
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #707070;
        width: 350px;
        margin-top: 8px;
        margin-bottom: 24px;
    }
    button {
        width: 504px;
    }
    &__text {
        margin-top: 24px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #707070;
        a {
            color: #1c454e;
            font-weight: 700;
            font-size: 14px;
            line-height: 120%;
        }
    }
}
